import esignOptOutDialogContent from './esign-opt-out-dialog-content.yml';
import acpEsignOptOutDialogDdaWarningFeaturePermissionMap from './acp-esign-opt-out-dialog-dda-warning-feature-permission-map';
import acpEsignOptOutDialogDdaWarningCtrl from './acp-esign-opt-out-dialog-dda-warning-ctrl';
import acpEsignOptOutDialogCtrl from './acp-esign-opt-out-dialog-ctrl';
import acpEsignOptOutDialogDdaWarningDirective from './acp-esign-opt-out-dialog-dda-warning-directive';
import acpEsignOptOutDialogDirective from './acp-esign-opt-out-dialog-directive';
import acpCore from 'core';
import ng from 'angular';
import './styles/${theme}/core.scss';

/**
 * @acpdoc component
 * @ngdoc module
 * @name acp.component.esign-opt-out-dialog
 *
 * @requires acp.core
 *
 * @description
 *
 * @example
 * ```html
 * <acp-esign-opt-out-dialog></acp-esign-opt-out-dialog>
 * ```
 *
 * ```js
 * angular.module('acp.component.esign-opt-out-dialog.example', [
 *   require('acp.component.esign-opt-out-dialog').name
 * ]);
 * ```
 */
var component = ng
  .module('acp.component.esign-opt-out-dialog', [acpCore.name])
  .directive('acpEsignOptOutDialog', acpEsignOptOutDialogDirective)
  .directive(
    'acpEsignOptOutDialogDdaWarning',
    acpEsignOptOutDialogDdaWarningDirective
  )
  .controller('AcpEsignOptOutDialogComponentCtrl', acpEsignOptOutDialogCtrl)
  .controller(
    'AcpEsignOptOutDialogDdaWarningCtrl',
    acpEsignOptOutDialogDdaWarningCtrl
  )
  .constant(
    'acpEsignOptOutDialogDdaWarningFeaturePermissionMap',
    acpEsignOptOutDialogDdaWarningFeaturePermissionMap
  )
  .run(
    /*@ngInject*/ function(contentSectionCache) {
      contentSectionCache.put(
        'components/esign-opt-out-dialog',
        esignOptOutDialogContent
      );
    }
  );

export default component;

export {
  default as acpEsignOptOutDialogTemplate
} from './templates/acp-esign-opt-out-dialog.html';
