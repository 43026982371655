// @ngInject
function AcpEsignOptOutDialogDdaWarningCtrl(
  $scope,
  nsUtil,
  acpEsignOptOutDialogDdaWarningFeaturePermissionMap
) {
  $scope.featurePermissionMap = acpEsignOptOutDialogDdaWarningFeaturePermissionMap;

  function init() {
    var permissions = $scope.featurePermissionMap.reduce(
      reduceFeatureItemsPermissions,
      []
    );
    $scope.$tiePermissions('permissions', nsUtil.uniq(permissions));
  }

  function removePermissionNegation(permissionName) {
    if (permissionName && permissionName[0] === '!') {
      permissionName = permissionName.substring(1);
    }
    return permissionName;
  }
  function removePermissionNegations(permission) {
    if (nsUtil.isArray(permission)) {
      return permission.map(removePermissionNegation);
    } else {
      return removePermissionNegation(permission);
    }
  }

  function reduceFeatureItemsPermissions(permissions, feature) {
    if (!feature.alwaysShow) {
      // feature.permission can be a string or an array of strings.
      permissions = permissions.concat(
        removePermissionNegations(feature.permission)
      );
    }
    return permissions;
  }

  $scope.hasPermission = function(permission) {
    if (!permission) return false;

    function checkPermission(permissionName) {
      // if permissionName begins with '!', check if the user does not have the permission
      var notFlag = permissionName[0] === '!';
      if (notFlag) {
        permissionName = removePermissionNegation(permissionName);
      }
      var permissionValue = !!$scope.permissions[permissionName];
      return !!(notFlag ^ permissionValue); // logical xor
    }

    if (nsUtil.isArray(permission)) {
      // every checkPermission call must evaluate to true to return true
      return permission.every(checkPermission);
    } else {
      return checkPermission(permission);
    }
  };

  init();
}

export default AcpEsignOptOutDialogDdaWarningCtrl;
