import acpEsignOptOutDialogTemplate from './templates/acp-esign-opt-out-dialog.html';
// @ngInject
function acpEsignOptOutDialogDirective() {
  return {
    controller: 'AcpEsignOptOutDialogComponentCtrl',
    restrict: 'E',
    scope: {},
    template: acpEsignOptOutDialogTemplate
  };
}

export default acpEsignOptOutDialogDirective;
