// @ngInject
function AcpEsignOptOutDialogComponentCtrl($scope, $nsDialog) {
  function init() {
    $scope.$tiePermissions('permissions', ['isDDA', 'hasTransferNowEligible']);
  }

  $scope.confirm = function () {
    $nsDialog.hide();
  };

  $scope.cancel = function () {
    $nsDialog.cancel();
  };

  init();
}

export default AcpEsignOptOutDialogComponentCtrl;
