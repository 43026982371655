import acpEsignOptOutDialogDdaWarningTemplate from './templates/acp-esign-opt-out-dialog-dda-warning.html';
// @ngInject
function acpEsignOptOutDialogDdaWarningDirective() {
  return {
    controller: 'AcpEsignOptOutDialogDdaWarningCtrl',
    restrict: 'E',
    scope: {},
    template: acpEsignOptOutDialogDdaWarningTemplate
  };
}

export default acpEsignOptOutDialogDdaWarningDirective;
