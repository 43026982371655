/**
 * This array defines the mapping between which features to show and the permission.
 *   It also serves as the feature list ordering.
 *
 *   The `ymlKey` value corresponds with a feature/title string in esign-opt-out-dialog-content.yml
 *   The feature list item is conditionally shown dependant on `permission` value coming from nsPermissions
 *
 */

var acpEsignOptOutDialogDdaWarningFeaturePermissionMap = [
  {
    ymlKey: 'overdraft',
    permission: ['isOverdraftAvailable', '!allowOverdraftWithoutEsign']
  },
  { ymlKey: 'savings', permission: 'isSavingsAvailable' },
  { ymlKey: 'p2p', permission: 'isSendMoneyAvailable' },
  { ymlKey: 'westernunion', permission: 'isWesternUnionAvailable' },
  { ymlKey: 'me2me', permission: 'isTransferNowAvailable' },
  { ymlKey: 'mobilecheck', permission: 'isMobileCheckAvailable' },
  { ymlKey: 'ecomm', alwaysShow: true }
];

export default acpEsignOptOutDialogDdaWarningFeaturePermissionMap;
